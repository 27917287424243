export function createAppInsightsErrorHandler(appInsights, config) {
    return function (err, vm, info) {
        var trace = "Error in ".concat(info, ": ").concat(generateComponentTrace(vm, config.name));
        appInsights.trackException(err, 'DefaultErrorHandler', { Cause: trace }, {}, 3);
    };
}
// --
// Taken (and slightly adapted) from Vue.js debug code
// https://github.com/vuejs/vue/blob/38e967b8067441ff37cb3b923200023a6b842759/src/core/util/debug.js
// --
function generateComponentTrace(vm, rootAlias) {
    if (!vm._isVue || !vm.$parent)
        return "\n\n(found in ".concat(formatComponentName(vm, rootAlias), ")");
    var tree = [];
    var currentRecursiveSequence = 0;
    while (vm) {
        if (tree.length > 0) {
            var last = tree[tree.length - 1];
            if (last.constructor === vm.constructor) {
                currentRecursiveSequence++;
                vm = vm.$parent;
                continue;
            }
            else if (currentRecursiveSequence > 0) {
                tree[tree.length - 1] = [last, currentRecursiveSequence];
                currentRecursiveSequence = 0;
            }
        }
        tree.push(vm);
        vm = vm.$parent;
    }
    var stack = tree.map(function (vm, i) {
        return (i === 0 ? '---> ' : repeat(' ', 5 + i * 2))
            + (!Array.isArray(vm) ? formatComponentName(vm, rootAlias) : "".concat(formatComponentName(vm[0], rootAlias), "... (").concat((vm[1], rootAlias), " recursive calls)"));
    });
    return '\n\nfound in\n\n' + stack.join('\n');
}
function classify(str) {
    return str.replace(/(?:^|[-_])(\w)/g, function (c) { return c.toUpperCase(); })
        .replace(/[-_]/g, '');
}
function formatComponentName(vm, rootAlias) {
    if (vm.$root === vm)
        return "<Root> of ".concat(rootAlias);
    var options = typeof (vm) !== 'function' || vm.cide == null
        ? (vm._isVue ? (vm.$options || vm.constructor.options) : vm)
        : vm;
    var name = options.name || options._componentTag;
    var file = options.__file;
    if (!name && file) {
        var match = file.match(/([^/\\]+)\.vue$/);
        name = match && match[1];
    }
    return (name ? "<".concat(classify(name), ">") : '<Anonymous>')
        + (file ? " at ".concat(file) : '');
}
function repeat(str, n) {
    var res = '';
    while (n) {
        if (n % 2 === 1)
            res += str;
        if (n > 1)
            str += str;
        n >>= 1;
    }
    return res;
}
