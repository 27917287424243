import { __awaiter, __generator } from "tslib";
var ExcludedPaths = ['/api/sessions'];
export function addRefreshUserMiddleware(httpClient, sessionService) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            httpClient.use(function (req, next) { return __awaiter(_this, void 0, void 0, function () {
                var res, user, session, deferredResponse_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, next(req)];
                        case 1:
                            res = _a.sent();
                            if (!((res.status === 401 || res.status === 403)
                                && res.request.method === 'GET'
                                && ExcludedPaths.every(function (p) { return res.request.url.indexOf(p) < 0; }))) return [3 /*break*/, 4];
                            user = sessionService.current();
                            if (user === null)
                                return [2 /*return*/, res];
                            return [4 /*yield*/, sessionService.get()];
                        case 2:
                            session = _a.sent();
                            if (session === null)
                                sessionService.logout();
                            else if (user.curtinId !== session.curtinId)
                                sessionService.setUser(session);
                            deferredResponse_1 = new Promise(function () { return res; });
                            setTimeout(function () { return Promise.resolve(deferredResponse_1); }, 0);
                            return [4 /*yield*/, deferredResponse_1];
                        case 3: return [2 /*return*/, _a.sent()];
                        case 4: return [2 /*return*/, res];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    });
}
