import { __decorate, __extends, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
import { injectable } from 'core/decorators';
export var AdminStudyComponentService = /** @class */ (function (_super) {
    __extends(AdminStudyComponentService, _super);
    function AdminStudyComponentService(client) {
        return _super.call(this, client) || this;
    }
    AdminStudyComponentService.prototype.SaveStudyPackageComponents = function (changes) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.patch('/study-components', changes); });
    };
    AdminStudyComponentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], AdminStudyComponentService);
    return AdminStudyComponentService;
}(ApiService));
