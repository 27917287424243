import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { OfferService } from 'enrolment/services';
export var AdminOfferService = /** @class */ (function (_super) {
    __extends(AdminOfferService, _super);
    function AdminOfferService(restClient) {
        return _super.call(this, restClient) || this;
    }
    AdminOfferService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], AdminOfferService);
    return AdminOfferService;
}(OfferService));
