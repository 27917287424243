import { __decorate, __extends, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
import { injectable } from 'core/decorators';
export var ImportantNoteService = /** @class */ (function (_super) {
    __extends(ImportantNoteService, _super);
    function ImportantNoteService(client) {
        return _super.call(this, client) || this;
    }
    ImportantNoteService.prototype.createImportantNote = function (importantNote) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post('/important-notes', importantNote); });
    };
    ImportantNoteService.prototype.saveImportantNote = function (importantNote) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/important-notes/".concat(encodeURIComponent(importantNote.name)), importantNote); });
    };
    ImportantNoteService.prototype.getImportantNotes = function () {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get('/important-notes'); });
    };
    ImportantNoteService.prototype.getImportantNote = function (name) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/important-notes/".concat(encodeURIComponent(name))); });
    };
    ImportantNoteService.prototype.deleteImportantNote = function (name) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.delete("/important-notes/".concat(encodeURIComponent(name))); });
    };
    ImportantNoteService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], ImportantNoteService);
    return ImportantNoteService;
}(ApiService));
