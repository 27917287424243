import { __awaiter, __generator } from "tslib";
import 'reflect-metadata';
import bootstrap from 'common/bootstrap';
import { registerAppDependencies } from 'enrolment/config/dependencies';
import { registerAppDependencies as registerAdminAppDependencies } from 'admin/config/dependencies';
import { Routes } from 'site/config/routes';
import { registerComponents } from 'site/config/components';
import { createAppInsightsErrorHandler } from 'common/utils/error-handlers';
import { attachPageViewTracking } from 'common/utils/analytics';
bootstrap({ name: 'enrolmentassistant.app' }).then(function (env) { return __awaiter(void 0, void 0, void 0, function () {
    var Vue, Config, VueInversify, Container, AppInsights, _a, VueRouter, Layout, appInsightsErrorHandler, router;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                Vue = env.Vue, Config = env.Config, VueInversify = env.VueInversify, Container = env.Container, AppInsights = env.AppInsights;
                return [4 /*yield*/, Promise.all([
                        import('vue-router'),
                        import('site/layout/bilya/layout.vue')
                    ])];
            case 1:
                _a = _b.sent(), VueRouter = _a[0].default, Layout = _a[1].default;
                Vue.use(VueRouter);
                Vue.use(VueInversify);
                return [4 /*yield*/, registerAppDependencies(Container, {})];
            case 2:
                _b.sent();
                return [4 /*yield*/, registerAdminAppDependencies(Container)];
            case 3:
                _b.sent();
                return [4 /*yield*/, registerComponents(Vue)];
            case 4:
                _b.sent();
                appInsightsErrorHandler = createAppInsightsErrorHandler(AppInsights, Config);
                router = new VueRouter({
                    mode: 'history',
                    routes: Routes
                });
                router.beforeEach(propagateRoutePropsHandler(router));
                attachPageViewTracking(router, AppInsights);
                return [2 /*return*/, new Vue({
                        el: '#app',
                        container: Container,
                        errorCaptured: function (err, vm, info) {
                            appInsightsErrorHandler(err, vm, info);
                        },
                        render: function (r) { return r(Layout); },
                        router: router
                    })];
        }
    });
}); });
function propagateRoutePropsHandler(router) {
    return function (to, from, next) {
        if (!shouldPropagateProps(to) || Object.keys(from.params).length === 0)
            return next();
        Object.assign(to.params, from.params);
        next(router.resolve(to).href);
    };
}
function shouldPropagateProps(route) {
    if (Object.keys(route.params).length > 0)
        return false;
    var shouldPropagate = false;
    var matchIndex = route.matched.length;
    while (matchIndex > 0 && !shouldPropagate) {
        matchIndex--;
        var definesBehaviour = route.matched[matchIndex].meta
            && (route.matched[matchIndex].meta.propagateProps === true
                || route.matched[matchIndex].meta.propagateProps === false);
        shouldPropagate = definesBehaviour ? route.matched[matchIndex].meta.propagateProps : shouldPropagate;
        if (definesBehaviour)
            break;
    }
    return shouldPropagate;
}
