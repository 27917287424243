import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService, RestError } from 'common/services/api';
export var StudentEnrolmentService = /** @class */ (function (_super) {
    __extends(StudentEnrolmentService, _super);
    function StudentEnrolmentService(restClient) {
        return _super.call(this, restClient) || this;
    }
    StudentEnrolmentService.prototype.getActiveEnrolment = function (userId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/students/".concat(encodeURIComponent(userId), "/enrolments/active")); })
            .catch(function (error) {
            if (error.name === RestError.Name && error.statusCode === 404)
                return null;
            else
                throw error;
        });
    };
    StudentEnrolmentService.prototype.createEnrolment = function (userId, offer) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.post("/students/".concat(encodeURIComponent(userId), "/enrolments"), offer); });
    };
    StudentEnrolmentService.prototype.saveStudyPlanComponents = function (userId, offerId, studyPlanComponents) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/students/".concat(encodeURIComponent(userId), "/offers/").concat(encodeURIComponent(offerId), "/enrolment/study-plan-components"), studyPlanComponents); });
    };
    StudentEnrolmentService.prototype.getStudyPlanComponents = function (userId, offerId) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/students/".concat(encodeURIComponent(userId), "/offers/").concat(encodeURIComponent(offerId), "/enrolment/study-plan-components")); });
    };
    StudentEnrolmentService.prototype.saveVaccinationEvidence = function (userId, hasSubmittedVaccineEvidence) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/students/".concat(encodeURIComponent(userId), "/vaccination"), { hasSubmittedVaccineEvidence: hasSubmittedVaccineEvidence }); });
    };
    StudentEnrolmentService.prototype.saveUsiSubmitted = function (userId, hasSubmittedUsi) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/students/".concat(encodeURIComponent(userId), "/usi-submitted"), { hasSubmittedUsi: hasSubmittedUsi }); });
    };
    StudentEnrolmentService.prototype.saveCareerSurveyCompleted = function (userId, hasCompletedCareerSurvey) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.put("/students/".concat(encodeURIComponent(userId), "/career-survey-completed"), { hasCompletedCareerSurvey: hasCompletedCareerSurvey }); });
    };
    StudentEnrolmentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], StudentEnrolmentService);
    return StudentEnrolmentService;
}(ApiService));
