import { __awaiter, __extends, __generator } from "tslib";
import { FriendlyError } from 'common/errors';
export var RestError = /** @class */ (function (_super) {
    __extends(RestError, _super);
    function RestError(messages, statusCode) {
        var _this = _super.call(this, messages) || this;
        _this.name = RestError.Name;
        _this.statusCode = statusCode;
        return _this;
    }
    RestError.Name = 'RestError';
    return RestError;
}(FriendlyError));
var ApiService = /** @class */ (function () {
    function ApiService(client) {
        this.client = client;
    }
    ApiService.prototype.sendRequest = function (fn) {
        return __awaiter(this, void 0, void 0, function () {
            var res, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fn()];
                    case 1:
                        res = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        throw new FriendlyError('An unexpected error occurred when contacting the server.', ex_1);
                    case 3:
                        if (res.status < 200 || res.status >= 400)
                            throw new RestError(res.errors, res.status);
                        return [2 /*return*/, res.data];
                }
            });
        });
    };
    return ApiService;
}());
export { ApiService };
