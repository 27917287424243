import { __decorate, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import env from 'env';
export var EnrolmentAssistantStepKeywordsMap = {
    'offers': { keywords: ['en_accept2'] },
    'conditions': { keywords: ['en_accept2'] },
    'eCAF': { keywords: ['en_ecaf'] },
    'study-components': { keywords: ['en_major'] },
    'units': { keywords: ['en_enrol'] },
    'class-registration': { keywords: ['en_class2'] },
    'other-tasks': { keywords: ['en_other'] }
};
export var ReenrolmentStepCategoryMap = {
    'units': { products: ['3.6'] },
    'class-registration': { products: ['3.1575'] },
    'career-wise': { products: [env.faqProductIds.reenrolmentCareerWise] }
};
export var HelpConfigProvider = /** @class */ (function () {
    function HelpConfigProvider() {
        this.config = {};
    }
    HelpConfigProvider.prototype.getHelpConfig = function () {
        return Promise.resolve(this.config);
    };
    HelpConfigProvider.prototype.setHelpConfig = function (config) {
        this.config = config;
    };
    HelpConfigProvider = __decorate([
        injectable(),
        __metadata("design:paramtypes", [])
    ], HelpConfigProvider);
    return HelpConfigProvider;
}());
