import { __awaiter, __generator } from "tslib";
import { OfferService, StudyComponentService, EnrolmentStepService, StudentEnrolmentService, SpkContentService, ImportantNoteService } from 'enrolment/services';
import { HelpConfigProvider, EnrolmentAssistantStepKeywordsMap } from 'common/services';
import { AppConfig } from './app-config';
export function registerAppDependencies(container, config) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            //TODO: Fix app dependencies being registered after widget initialisation and allow this to be on here in onActivation
            container.bind(AppConfig).toConstantValue(new AppConfig(config));
            container.get(HelpConfigProvider).setHelpConfig(EnrolmentAssistantStepKeywordsMap);
            container.bind(OfferService).toSelf().inSingletonScope();
            container.bind(StudyComponentService).toSelf().inSingletonScope();
            container.bind(StudentEnrolmentService).toSelf().inSingletonScope();
            container.bind(EnrolmentStepService).toSelf().inSingletonScope();
            container.bind(ImportantNoteService).toSelf().inSingletonScope();
            container.bind(SpkContentService).toSelf().inSingletonScope();
            return [2 /*return*/, container];
        });
    });
}
