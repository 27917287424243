import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
export var StudyComponentService = /** @class */ (function (_super) {
    __extends(StudyComponentService, _super);
    function StudyComponentService(client) {
        return _super.call(this, client) || this;
    }
    StudyComponentService.prototype.searchStudyPackageComponents = function (query) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("/study-components?query=".concat(encodeURIComponent(query))); });
    };
    StudyComponentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], StudyComponentService);
    return StudyComponentService;
}(ApiService));
