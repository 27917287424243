import { __decorate, __metadata } from "tslib";
/* eslint-disable @typescript-eslint/no-explicit-any */
import env from 'env';
import { injectable } from 'core/decorators';
export var AppInsights = /** @class */ (function () {
    function AppInsights() {
        var _this = this;
        if (env.applicationInsights.enabled) {
            var snippet_1 = {
                queue: [],
                config: {
                    instrumentationKey: env.applicationInsights.instrumentationKey,
                    enableDebug: !!env.applicationInsights.enableDebug
                }
            };
            this.proxy = createProxy(snippet_1);
            loadAppInsights().then(function (ctor) {
                var init = new ctor(snippet_1);
                if (!_this.proxy)
                    throw new Error();
                _this.instance = _this.proxy.instance = init.loadAppInsights();
                init.emptyQueue();
                init.addHousekeepingBeforeUnload(_this.instance);
            }, function (err) { return console.error('Failed to load Application Insights', err); });
        }
    }
    Object.defineProperty(AppInsights.prototype, "appInsights", {
        get: function () {
            return this.instance || this.proxy;
        },
        enumerable: false,
        configurable: true
    });
    AppInsights.prototype.trackPageView = function (name, url, properties, measurements, duration) {
        return this.appInsights && this.appInsights.trackPageView(name, url, properties, measurements, duration);
    };
    AppInsights.prototype.startTrackPage = function (name) {
        return this.appInsights && this.appInsights.startTrackPage(name);
    };
    AppInsights.prototype.stopTrackPage = function (name, url) {
        return this.appInsights && this.appInsights.stopTrackPage(name, url);
    };
    AppInsights.prototype.trackEvent = function (name, properties) {
        return this.appInsights && this.appInsights.trackEvent(name, properties);
    };
    AppInsights.prototype.trackException = function (exception, handledAt, properties, measurements, severityLevel) {
        return this.appInsights && this.appInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
    };
    AppInsights.prototype.setAuthenticatedUserContext = function (authenticatedUserId, accountId) {
        return this.appInsights && this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId);
    };
    AppInsights.prototype.clearAuthenticatedUserContext = function () {
        return this.appInsights && this.appInsights.clearAuthenticatedUserContext();
    };
    AppInsights = __decorate([
        injectable(),
        __metadata("design:paramtypes", [])
    ], AppInsights);
    return AppInsights;
}());
function createProxy(snippet) {
    var proxy = {
        snippet: snippet,
        instance: null
    };
    var methods = [
        'clearAuthenticatedUserContext',
        'flush',
        'setAuthenticatedUserContext',
        'startTrackEvent',
        'startTrackPage',
        'stopTrackEvent',
        'stopTrackPage',
        'trackDependency',
        'trackEvent',
        'trackException',
        'trackMetric',
        'trackPageView',
        'trackTrace'
    ];
    var createProxyMethod = function (method) {
        return function () {
            var _a;
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (this.instance)
                return (_a = this.instance)[method].apply(_a, args);
            this.snippet.queue.push(function () {
                var _a;
                return (_a = _this.instance)[method].apply(_a, args);
            });
        };
    };
    methods.forEach(function (m) { return proxy[m] = createProxyMethod(m); });
    return proxy;
}
function loadAppInsights() {
    var objectPath = ['Microsoft', 'ApplicationInsights', 'Initialization'];
    var urls = ['https://az416426.vo.msecnd.net/scripts/a/ai.0.js'];
    return new Promise(function (resolve, reject) {
        var ctor = getCtor();
        // Easiest case, already exists
        if (ctor != null)
            return resolve(ctor);
        // If the script doesn't exist on the page, add it and wait for it to complete
        if (!urls.some(function (url) { return document.querySelector("script[src=\"".concat(url, "\"]")); }))
            return addScript();
        // Script does exist on the page, poll for completion
        // NOTE: There's no way (that I know) to check if a script tag already fired load/error events
        //  so this is the only way I could think of to try and replicate the behaviour.
        var interval = setInterval(function () {
            var ctor = getCtor();
            if (ctor != null) {
                clearInterval(interval || undefined);
                interval = null;
                resolve(ctor);
            }
        }, 100);
        // Give up on the existing script after 30 seconds and try re-add it
        setTimeout(function () {
            if (interval != null && !getCtor()) {
                clearInterval(interval);
                addScript();
            }
        }, 30 * 1000);
        function addScript() {
            var script = document.createElement('script');
            script.setAttribute('src', urls[0]);
            script.setAttribute('type', 'text/javascript');
            script.addEventListener('error', reject);
            script.addEventListener('load', function () {
                var ctor = getCtor();
                return ctor ? resolve(ctor) : reject();
            });
            document.head.appendChild(script);
        }
    });
    function getCtor() {
        var obj = window;
        for (var i = 0, j = objectPath.length; i < j && obj; ++i)
            obj = obj[objectPath[i]];
        return obj;
    }
}
