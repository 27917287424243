import { __awaiter, __generator } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { StorageService } from 'core/services/storage';
import { UserSessionService, FileUploadService, PersonService, HelpService, HelpConfigProvider } from 'common/services';
import { AppInsights } from 'common/app-insights';
import { addSessionEventHandlers } from 'common/config/session-events';
import { HttpClient } from 'core/services/http-client';
import { addWebApiMiddleware } from './http-middleware';
import { addRefreshUserMiddleware } from './refresh-user-middleware';
export function registerCoreDependencies(container, config) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            container.bind(HttpClient).toSelf().inSingletonScope().onActivation(function (_, client) {
                addWebApiMiddleware(client, config);
                return client;
            });
            container.bind(AppInsights).toSelf().inSingletonScope();
            container.bind(RestClient).toSelf().inSingletonScope();
            container.bind(StorageService).toConstantValue(new StorageService(window.sessionStorage));
            container.bind(UserSessionService).toSelf().inSingletonScope().onActivation(function (ctx, sessionService) {
                addSessionEventHandlers(sessionService, ctx.container.get(AppInsights));
                addRefreshUserMiddleware(ctx.container.get(HttpClient), sessionService);
                return sessionService;
            });
            container.bind(FileUploadService).toSelf().inSingletonScope();
            container.bind(PersonService).toSelf().inSingletonScope();
            container.bind(HelpService).toSelf().inSingletonScope();
            container.bind(HelpConfigProvider).toSelf().inSingletonScope();
            return [2 /*return*/, container];
        });
    });
}
