import { AbstractRoute } from 'common/utils/routes';
import { Routes as EnrolmentRoute } from 'enrolment/config/routes';
export var AdminRoutes = [
    { name: 'dashboard', path: '', component: function () { return import('admin/views/dashboard.vue'); } },
    {
        name: 'upload-study-components', path: 'upload-study-components',
        component: function () { return import('admin/views/upload-study-components.vue'); },
        meta: { title: 'Upload study components' }
    },
    {
        name: 'student-lookup', path: 'student-lookup/:userId?',
        component: function () { return import('admin/views/student-lookup.vue'); },
        meta: { title: 'Student lookup' },
        props: propMap({ userId: OptionalString })
    },
    {
        name: 'spk-content-lookup', path: 'spk-content-lookup/:spkCode?',
        component: function () { return import('admin/views/spk-content-lookup.vue'); },
        meta: { title: 'Spk content lookup' },
        props: propMap({ spkCode: OptionalString })
    },
    {
        name: 'block-screens', path: 'block-screens',
        component: function () { return import('admin/views/block-screens.vue'); },
        meta: { title: 'Block screens' }
    },
    {
        path: 'important-notes', component: AbstractRoute, children: [
            {
                name: 'important-notes.create',
                path: 'create',
                component: function () { return import('admin/views/important-notes/edit.vue'); },
                props: { name: null },
                meta: { title: 'Create important note' }
            },
            {
                name: 'important-notes.edit',
                path: ':name',
                component: function () { return import('admin/views/important-notes/edit.vue'); },
                props: propMap({ name: String }),
                meta: { title: 'Edit important note' }
            },
            {
                name: 'important-notes.list',
                path: '',
                component: function () { return import('admin/views/important-notes/list.vue'); },
                meta: { title: 'View important notes' }
            }
        ]
    },
    {
        path: 'student-preview/:userId(\\d+)?',
        meta: { title: 'Student Preview', propagateProps: true },
        component: function () { return import('admin/views/student-preview.vue'); },
        props: propMap({ userId: OptionalString }),
        children: EnrolmentRoute
    }
];
function OptionalString(value) {
    return value == null ? null : String(value);
}
function propMap(propMappers) {
    return function (route) {
        var mappedProps = {};
        for (var propName in propMappers) {
            var mapper = propMappers[propName];
            var propValue = route.params[propName];
            mappedProps[propName] = typeof (mapper) === 'function' ? mapper(propValue) : propValue;
        }
        return mappedProps;
    };
}
