import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
import { injectable } from 'core/decorators';
export var ImportantNoteService = /** @class */ (function (_super) {
    __extends(ImportantNoteService, _super);
    function ImportantNoteService(client) {
        var _this = _super.call(this, client) || this;
        _this.loadedImportantNotes = null;
        return _this;
    }
    ImportantNoteService.prototype.getActiveImportantNotes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.loadedImportantNotes === null)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.sendRequest(function () { return _this.client.get('/important-notes/active'); })];
                    case 1:
                        _a.loadedImportantNotes = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.loadedImportantNotes];
                }
            });
        });
    };
    ImportantNoteService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], ImportantNoteService);
    return ImportantNoteService;
}(ApiService));
