import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { StudentEnrolmentService } from 'enrolment/services';
export var AdminStudentEnrolmentService = /** @class */ (function (_super) {
    __extends(AdminStudentEnrolmentService, _super);
    function AdminStudentEnrolmentService(restClient) {
        return _super.call(this, restClient) || this;
    }
    AdminStudentEnrolmentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], AdminStudentEnrolmentService);
    return AdminStudentEnrolmentService;
}(StudentEnrolmentService));
