import { __decorate, __metadata } from "tslib";
import { injectable } from 'core/decorators';
export var AppConfig = /** @class */ (function () {
    function AppConfig(config) {
        if (config)
            Object.assign(this, config);
    }
    AppConfig = __decorate([
        injectable(),
        __metadata("design:paramtypes", [Object])
    ], AppConfig);
    return AppConfig;
}());
