import env from 'env';
import uuid from 'core/uuid';
export function addWebApiMiddleware(httpClient, appConfig) {
    httpClient.use(function (req, next) {
        if (req.url.indexOf(env.urls.api) < 0 && !/^https?:\/\//i.test(req.url))
            req.url = env.urls.api + req.url;
        req.credentials = 'include';
        req.headers = req.headers || {};
        req.headers['X-Correlation-Id'] = uuid();
        req.headers['X-Consumer-Id'] = appConfig.name;
        req.headers['Cache-Control'] = 'no-cache';
        req.headers['Pragma'] = 'no-cache';
        return next(req);
    });
}
