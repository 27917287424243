import { __awaiter, __generator } from "tslib";
import { SpkContentService } from 'admin/services/spk-content-service';
import { AdminStudyComponentService } from 'admin/services/study-component-service';
import { ImportantNoteService } from 'admin/services/important-note-service';
import { PreviewOfferService } from 'admin/services/preview-offer-service';
import { PreviewStudentEnrolmentService } from 'admin/services/preview-student-enrolment-service';
import { StudentEnrolmentService, OfferService } from 'enrolment/services';
import { AdminStudentEnrolmentService } from 'admin/services/admin-student-enrolment-service';
import { AdminOfferService } from 'admin/services/admin-offer-service';
export function registerAppDependencies(container) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            //bind to same singleton instance
            container.bind(PreviewOfferService).toSelf().inSingletonScope();
            container.bind(PreviewStudentEnrolmentService).toSelf().inSingletonScope();
            // Use preview services in place of original
            container.rebind(OfferService).toService(PreviewOfferService);
            container.rebind(StudentEnrolmentService).toService(PreviewStudentEnrolmentService);
            container.bind(SpkContentService).toSelf().inSingletonScope();
            container.bind(AdminStudyComponentService).toSelf().inSingletonScope();
            container.bind(ImportantNoteService).toSelf().inSingletonScope();
            container.bind(AdminOfferService).toSelf().inSingletonScope();
            container.bind(AdminStudentEnrolmentService).toSelf().inSingletonScope();
            return [2 /*return*/, container];
        });
    });
}
