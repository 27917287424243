export var Routes = [
    { name: 'landing', path: '', component: function () { return import('enrolment/views/landing.vue'); } },
    { path: '', component: function () { return import('enrolment/index.vue'); }, children: [
            { name: 'offers', path: 'offers', component: function () { return import('enrolment/views/offers.vue'); }, meta: { title: 'Offers' } },
            { name: 'conditions', path: 'conditions', component: function () { return import('enrolment/views/conditions.vue'); }, meta: { title: 'Conditions' } },
            { name: 'usi', path: 'usi', component: function () { return import('enrolment/views/unique-student-identifier.vue'); }, meta: { title: 'USI' } },
            { name: 'eCAF', path: 'ecaf', component: function () { return import('enrolment/views/ecaf.vue'); }, meta: { title: 'eCAF' } },
            { name: 'study-components', path: 'study-components', component: function () { return import('enrolment/views/study-components.vue'); }, meta: { title: 'Major or Stream' } },
            { name: 'units', path: 'enrol-units', component: function () { return import('enrolment/views/enrol-units.vue'); }, meta: { title: 'Enrol In Units' } },
            { name: 'vaccination-evidence', path: 'vaccination-evidence', component: function () { return import('enrolment/views/vaccination-evidence.vue'); }, meta: { title: 'Vaccination' } },
            { name: 'class-registration', path: 'class-registration', component: function () { return import('enrolment/views/class-registration.vue'); }, meta: { title: 'Class Registration' } },
            { name: 'career-wise', path: 'map-career', component: function () { return import('enrolment/views/career-wise.vue'); }, meta: { title: 'Map your career' } },
            { name: 'other-tasks', path: 'continue-experience', component: function () { return import('enrolment/views/other-tasks.vue'); }, meta: { title: 'Continue your experience' } }
        ]
    },
    { name: 'not-found', path: '*', component: function () { return import('common/views/not-found.vue'); } }
];
