import { __decorate, __extends, __metadata } from "tslib";
import { SessionService } from 'core/services/session';
import { injectable } from 'core/decorators/inject';
import { RestClient } from 'core/services/rest-client';
import { StorageService } from 'core/services/storage';
export var UserSessionService = /** @class */ (function (_super) {
    __extends(UserSessionService, _super);
    function UserSessionService(restClient, storageService) {
        return _super.call(this, restClient, storageService) || this;
    }
    UserSessionService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient, StorageService])
    ], UserSessionService);
    return UserSessionService;
}(SessionService));
