import { __assign, __decorate } from "tslib";
import { injectable } from 'core/decorators';
var DomesticFeePayingLiabilityCategoryCode = '5';
var InternationalOnshoreLiabilityCategoryCode = '3';
function liabilityCategoryIsDomesticFeePaying(enrolment) {
    return (enrolment === null || enrolment === void 0 ? void 0 : enrolment.liabilityCategoryCode) === DomesticFeePayingLiabilityCategoryCode;
}
function liabilityCategoryIsInternationalOnshore(enrolment) {
    return (enrolment === null || enrolment === void 0 ? void 0 : enrolment.liabilityCategoryCode) === InternationalOnshoreLiabilityCategoryCode;
}
export var BlockRules = {
    offerNotSelected: { type: 'locked', component: function () { return import('enrolment/components/blocks/offer-not-selected.vue'); }, rule: function (context) { return !context.enrolment; } },
    notAdmitted: { type: 'locked', component: function () { return import('enrolment/components/blocks/not-admitted.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.offerStatus !== 'Admitted'; } },
    conditionsInapplicable: { type: 'complete', component: function () { return import('enrolment/components/blocks/conditions-inapplicable.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.conditions.length === 0; } },
    conditionsSatisfied: { type: 'complete', component: function () { return import('enrolment/components/blocks/conditions-satisfied.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.conditions.every(function (c) { return c.satisfied; }); } },
    usiInapplicable: { type: 'complete', component: function () { return import('enrolment/components/blocks/usi-inapplicable.vue'); }, rule: function (context) { return !!context.enrolment && /*@__NOINLINE__*/ liabilityCategoryIsInternationalOnshore(context.enrolment) === false; } },
    usiIncomplete: { type: 'locked', component: function () { return import('enrolment/components/blocks/usi-incomplete.vue'); }, rule: function () { return false; } },
    ecafApproved: { type: 'complete', component: function () { return import('enrolment/components/blocks/ecaf-approved.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.ecafApproved; } },
    ecafOptional: { type: 'incomplete', component: function () { return import('enrolment/components/blocks/ecaf-optional.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.ecafApproved && !context.enrolment.ecafRequired && liabilityCategoryIsDomesticFeePaying(context.enrolment); } },
    ecafInapplicable: { type: 'complete', component: function () { return import('enrolment/components/blocks/ecaf-inapplicable.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.ecafRequired; } },
    ecaf2025: { type: 'locked', component: function () { return import('enrolment/components/blocks/ecaf-2025.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.ecafApproved && context.enrolment.availabilityYear >= 2025 && (context.enrolment.ecafRequired || liabilityCategoryIsDomesticFeePaying(context.enrolment)); } },
    ecafUnapproved: { type: 'locked', component: function () { return import('enrolment/components/blocks/ecaf-unapproved.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.ecafRequired && !context.enrolment.ecafApproved; } },
    studyComponentsInapplicable: { type: 'complete', component: function () { return import('enrolment/components/blocks/study-components-inapplicable.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.courseHasStudyComponents; } },
    studyComponentsNotSelected: { type: 'locked', component: function () { return import('enrolment/components/blocks/study-components-not-selected.vue'); }, rule: function (context) { return !!context.enrolment && context.enrolment.courseHasStudyComponents && (context.enrolment.studyPlanComponents === null || context.enrolment.studyPlanComponents.length === 0); } },
    studyPlanNotExpanded: { type: 'locked', component: function () { return import('enrolment/components/blocks/study-plan-not-expanded.vue'); }, rule: function () { return false; } },
    uniReadyStudyPlanNotExpanded: { type: 'locked', component: function () { return import('enrolment/components/blocks/uniready-study-plan-not-expanded.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.studyPlanExpanded && context.enrolment.courseSpkCode.toLowerCase() === 'en-unirdy'; } },
    noEnrolledUnits: { type: 'locked', component: function () { return import('enrolment/components/blocks/no-enrolled-units.vue'); }, rule: function (context) { return !!context.enrolment && !context.enrolment.hasEnrolledUnits; } },
};
var asStepsTypes = function (e) { return Object.keys(e)
    .reduce(function (acc, key) {
    var stepKey = key;
    acc[stepKey] = __assign(__assign({}, e[stepKey]), { name: stepKey });
    return acc;
}, {}); };
export var Steps = asStepsTypes({
    'offers': {
        label: 'Accept or defer an offer',
        blockRules: [],
        isComplete: function (c) { return !!c.enrolment; },
        isHidden: function (c) { var _a; return !!((_a = c.enrolment) === null || _a === void 0 ? void 0 : _a.isAdvancingAdmissionsOffer); }
    },
    'conditions': {
        label: 'Meet your conditions',
        blockRules: [BlockRules.offerNotSelected, BlockRules.conditionsInapplicable, BlockRules.conditionsSatisfied],
        isComplete: function () { return false; },
        isHidden: function (c) { var _a; return !!((_a = c.enrolment) === null || _a === void 0 ? void 0 : _a.isAdvancingAdmissionsOffer); }
    },
    'usi': {
        label: 'Submit your USI',
        blockRules: [BlockRules.offerNotSelected, BlockRules.usiInapplicable],
        isComplete: function (c) { return !!c.enrolment && !!c.enrolment.hasSubmittedUsi; }
    },
    'units': {
        label: 'Enrol in units',
        blockRules: [BlockRules.offerNotSelected, BlockRules.notAdmitted, BlockRules.usiIncomplete, BlockRules.studyComponentsNotSelected, BlockRules.uniReadyStudyPlanNotExpanded, BlockRules.studyPlanNotExpanded],
        isComplete: function (c) { return !!c.enrolment && c.enrolment.offerStatus === 'Admitted' && c.enrolment.hasEnrolledUnits && (c.enrolment.hasSubmittedUsi || BlockRules.usiInapplicable.rule(c)); }
    },
    'class-registration': {
        label: 'Register for classes',
        blockRules: [BlockRules.offerNotSelected, BlockRules.notAdmitted, BlockRules.usiIncomplete, BlockRules.studyComponentsNotSelected, BlockRules.uniReadyStudyPlanNotExpanded, BlockRules.studyPlanNotExpanded],
        isComplete: function () { return false; }
    },
    'eCAF': {
        label: 'Complete eCAFs',
        blockRules: [BlockRules.offerNotSelected, BlockRules.ecafApproved, BlockRules.notAdmitted, BlockRules.usiIncomplete, BlockRules.studyComponentsNotSelected, BlockRules.uniReadyStudyPlanNotExpanded, BlockRules.studyPlanNotExpanded, BlockRules.ecaf2025, BlockRules.ecafOptional, BlockRules.ecafInapplicable],
        isComplete: function () { return false; }
    },
    'career-wise': {
        label: 'Map your career',
        blockRules: [BlockRules.offerNotSelected],
        isComplete: function (c) { return !!c.enrolment && !!c.enrolment.hasCompletedCareerSurvey; }
    },
    'other-tasks': {
        label: 'Continue your experience',
        blockRules: [],
        isComplete: function () { return false; },
        includeInProgress: false
    }
});
export var EnrolmentStepService = /** @class */ (function () {
    function EnrolmentStepService() {
    }
    EnrolmentStepService.prototype.getSteps = function (enrolment) {
        var context = { enrolment: enrolment };
        var stepKeys = Object.keys(Steps).filter(function (s) {
            var step = Steps[s];
            return (typeof step.isHidden === 'undefined') || step.isHidden(context) === false;
        });
        return stepKeys.map(function (s) {
            var stepKey = s;
            var stepModel = Steps[stepKey];
            var step = {
                name: stepKey,
                label: stepModel.label,
                complete: false,
                status: null,
                disabled: false,
                includeInProgress: typeof stepModel.includeInProgress === 'undefined' ? true : stepModel.includeInProgress
            };
            setStepState(step, context);
            return step;
        });
    };
    EnrolmentStepService.prototype.updateStepsState = function (steps, enrolment) {
        var context = { enrolment: enrolment };
        steps.forEach(function (step) { return setStepState(step, context); });
    };
    EnrolmentStepService.prototype.getCurrentStep = function (steps) {
        return steps.find(function (s) { return s.status === null && !s.complete; }) || steps.find(function (s) { return !!s.status; }) || steps[steps.length - 1];
    };
    EnrolmentStepService.prototype.getBlockMessage = function (step, enrolment) {
        var stepModel = Steps[step];
        if (!stepModel)
            return null;
        var context = { enrolment: enrolment };
        var rule = stepModel.blockRules.find(function (r) { return r.rule(context); });
        return rule ? rule.component : null;
    };
    EnrolmentStepService = __decorate([
        injectable()
    ], EnrolmentStepService);
    return EnrolmentStepService;
}());
function getStepStatus(step, context) {
    if (step.isComplete(context))
        return 'complete';
    var blockRule = step.blockRules.find(function (r) { return r.rule(context); });
    if (blockRule)
        return blockRule.type;
    return null;
}
function setStepState(step, context) {
    var status = getStepStatus(Steps[step.name], context);
    step.complete = step.complete || status === 'complete';
    step.status = status === 'complete' || status === 'incomplete' || status === null ? null : status;
}
