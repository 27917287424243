import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from './api';
export var PersonService = /** @class */ (function (_super) {
    __extends(PersonService, _super);
    function PersonService(client) {
        return _super.call(this, client) || this;
    }
    PersonService.prototype.getPersonById = function (id, type) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                url = "/people/".concat(encodeURIComponent(id)) + (type != null ? "?type=".concat(encodeURIComponent(type)) : '');
                return [2 /*return*/, this.sendRequest(function () { return _this.client.get(url); })];
            });
        });
    };
    PersonService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], PersonService);
    return PersonService;
}(ApiService));
