import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
import env from 'env';
export var OfferService = /** @class */ (function (_super) {
    __extends(OfferService, _super);
    function OfferService(client) {
        return _super.call(this, client) || this;
    }
    OfferService.prototype.getOffersByUser = function (userId) {
        var _this = this;
        var url = "".concat(env.urls.api, "/students/").concat(encodeURIComponent(userId), "/offers/");
        return this.sendRequest(function () { return _this.client.get(url); });
    };
    OfferService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], OfferService);
    return OfferService;
}(ApiService));
