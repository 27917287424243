import { __decorate, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { OfferService } from 'enrolment/services';
import { RestClient } from 'core/services/rest-client';
export var PreviewOfferService = /** @class */ (function () {
    function PreviewOfferService(client) {
        this.previewOffers = getDefaultOffers();
        this.offerService = new OfferService(client);
    }
    PreviewOfferService.prototype.getOffersByUser = function () {
        return Promise.resolve(this.previewOffers);
    };
    PreviewOfferService.prototype.addOffer = function (offer) {
        this.previewOffers.push(offer);
    };
    PreviewOfferService.prototype.fetchUserOffers = function (userId) {
        return this.offerService.getOffersByUser(userId);
    };
    PreviewOfferService.prototype.clearOffers = function () {
        this.previewOffers = [];
    };
    PreviewOfferService.prototype.resetOffers = function () {
        this.previewOffers = getDefaultOffers();
    };
    PreviewOfferService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], PreviewOfferService);
    return PreviewOfferService;
}());
function getDefaultOffers() {
    return [
        {
            id: 1,
            spkCode: 'B-COMM',
            spkNumber: 319266,
            spkVersion: 1,
            title: 'Bachelor of Education (Secondary)',
            duration: '4 years',
            credits: 800,
            availabilityYear: 2020,
            studyPeriod: 'Semester 1',
            isAdvancingAdmissionsOffer: true,
            conditions: [
                {
                    studentNumber: '11000033',
                    sspNumber: 101010,
                    parentSspNumber: 101011,
                    offerStatusCode: 'OF',
                    offerStatusCodeDescription: 'Offered',
                    spkNumber: 101010,
                    availabilityYear: 2020,
                    studyPeriodCode: 'Semester 1',
                    requisiteType: 'requisiteType',
                    requisiteDetails: 'requisiteDetails',
                    satisfied: true,
                    spkCode: '101010'
                }
            ]
        },
        {
            id: 2,
            spkCode: 'B-ENG',
            spkNumber: 319333,
            spkVersion: 1,
            title: 'Bachelor of Engineering (Honours)',
            duration: '5 years',
            credits: 1000,
            availabilityYear: 2020,
            studyPeriod: 'Semester 1',
            isAdvancingAdmissionsOffer: false,
            conditions: []
        }
    ];
}
