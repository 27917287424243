import { __decorate, __extends, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { RestClient } from 'core/services/rest-client';
import { ApiService } from 'common/services/api';
import env from 'env';
export var SpkContentService = /** @class */ (function (_super) {
    __extends(SpkContentService, _super);
    function SpkContentService(client) {
        return _super.call(this, client) || this;
    }
    SpkContentService.prototype.getSpkContent = function (spkCode) {
        var _this = this;
        return this.sendRequest(function () { return _this.client.get("".concat(env.urls.api, "/spk-content/").concat(encodeURIComponent(spkCode))); });
    };
    SpkContentService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [RestClient])
    ], SpkContentService);
    return SpkContentService;
}(ApiService));
