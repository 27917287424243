import { __awaiter, __decorate, __generator, __metadata } from "tslib";
import { injectable } from 'core/decorators';
import { HttpClient } from 'core/services/http-client';
import { FriendlyError } from 'common/errors';
import { HelpConfigProvider } from './help-config-provider';
import env from 'env';
export var HelpService = /** @class */ (function () {
    function HelpService(httpClient, helpConfigService) {
        this.context = null;
        this.httpClient = httpClient;
        this.helpConfigProvider = helpConfigService;
    }
    HelpService.prototype.setHelpContext = function (context) {
        this.context = context;
    };
    HelpService.prototype.getFaqProvider = function () {
        var _this = this;
        return function () { return _this.context ? _this.getFaqs() : Promise.resolve([]); };
    };
    HelpService.prototype.getFaqs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var helpConfig, config, queryParams, result, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!this.context)
                            return [2 /*return*/, []];
                        return [4 /*yield*/, this.helpConfigProvider.getHelpConfig()];
                    case 1:
                        helpConfig = _a.sent();
                        config = helpConfig[this.context.activeStep];
                        queryParams = [];
                        if (config.keywords)
                            queryParams.push("keywords=".concat(encodeURIComponent(config.keywords.join(','))));
                        if (config.products)
                            queryParams.push("products=".concat(encodeURIComponent(config.products.join(','))));
                        return [4 /*yield*/, this.httpClient.get("".concat(env.urls.crmInt, "/api/GetFaqs?").concat(queryParams.join('&')))];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, JSON.parse(result.body)];
                    case 3:
                        ex_1 = _a.sent();
                        throw new FriendlyError('An unexpected error occurred when contacting the server.', ex_1);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HelpService = __decorate([
        injectable(),
        __metadata("design:paramtypes", [HttpClient, HelpConfigProvider])
    ], HelpService);
    return HelpService;
}());
